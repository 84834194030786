export default defineNuxtRouteMiddleware(async (to, _) => {
  if (!to.path.endsWith('/')) {
    return;
  }
  const { path, query, hash } = to;
  const newPath = path.replace(/\/+$/, '');
  return await navigateTo(
    { path: newPath, query, hash },
    { redirectCode: 308, replace: true }
  );
});
